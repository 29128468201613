<template>
  <div class="login-container d-f j-c a-c">
    <div class="left">
      <h2>智慧校园管理平台</h2>
      <h3>优秀的统一集控管理平台</h3>
    </div>
    <router-view></router-view>
    <div class="record">
      ©2021 XNIU 苏公网安备 32011502011289号 苏ICP备2020065401号 江苏小牛电子科技有限公司
    </div>
  </div>
</template>

<script>
export default {
  name: "Login",
  data() {
    return {};
  },
  methods: {},
};
</script>

<style scoped lang="less">
.login-container {
  width: 100vw;
  min-height: 100vh;
  background-image: url("~@/assets/images/login_bac.png");
  background-size: 100% 100%;
  position: relative;
  .left {
    width: 535px;
    height: 613px;
    background-image: url("~@/assets/images/login_left_bac.png");
    background-size: 100% 100%;
    padding: 48px 0 0 48px;
    box-sizing: border-box;
    h2 {
      font-size: 24px;
      font-family: "PingFang SC";
      font-weight: bold;
      color: #06628d;
    }
    h3 {
      font-size: 12px;
      font-family: "PingFang SC";
      font-weight: 400;
      color: #06628d;
      margin-top: 8px;
    }
  }
  .record {
    position: absolute;
    bottom: 32px;
    font-size: 12px;
    font-family: "PingFang SC";
    font-weight: 400;
    color: #ffffff;
  }
}
</style>
